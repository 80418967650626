import React, { useState, useEffect } from "react";
import mapImage from "../images/map-not-dot.svg";
import pinImage from "../images/pin.svg";
import pinDotImage from "../images/pin-dot.svg";
import "../styles/Company.css";
import hoverImage from "../images/hover.svg";

export default function Company() {
  const [hoveredCity, setHoveredCity] = useState("");
  const [currentCityIndex, setCurrentCityIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const cities = ["Boston", "Istanbul", "Amsterdam", "Berlin"];

  useEffect(() => {
    // Ekran genişliğini kontrol eden event listener
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Mobil görünümde otomatik şehir değiştirme
    let interval;
    if (isMobile) {
      interval = setInterval(() => {
        setIsTransitioning(true); // Animasyon başlamadan önce
        setTimeout(() => {
          setCurrentCityIndex((prevIndex) => (prevIndex + 1) % cities.length);
          setIsTransitioning(false); // Animasyon tamamlandığında
        }, 500); // Animasyon süresiyle uyumlu olması için 0.5 saniye sonra şehir değişimi
      }, 2000); // 2 saniyede bir şehir değişimi
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isMobile, cities.length]);

  const handleMouseOver = (city) => {
    if (!isMobile) {
      setHoveredCity(city);
    }
  };

  const handleMouseOut = () => {
    if (!isMobile) {
      setHoveredCity("");
    }
  };

  return (
    <div className="company-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="map-container">
              <img className="company-map" src={mapImage} alt="Map" />
              {/* Şehir pinleri */}
              <div
                className="pin"
                style={{ top: "36.5%", left: "25%" }}
                onMouseOver={() => handleMouseOver("Boston")}
                onMouseOut={handleMouseOut}
              >
                <img src={pinDotImage} alt="Pin" className="pin-image" />
              </div>

              <div
                className="pin"
                style={{ top: "38%", left: "55%" }}
                onMouseOver={() => setHoveredCity("Istanbul")}
                onMouseOut={() => setHoveredCity("")}
              >
                <img src={pinDotImage} alt="Pin" className="pin-image" />
              </div>

              <div
                className="pin"
                style={{ top: "32.5%", left: "50%" }}
                onMouseOver={() => setHoveredCity("Amsterdam")}
                onMouseOut={() => setHoveredCity("")}
              >
                <img src={pinDotImage} alt="Pin" className="pin-image" />
              </div>

              <div
                className="pin"
                style={{ top: "31%", left: "52.4%" }}
                onMouseOver={() => setHoveredCity("Berlin")}
                onMouseOut={() => setHoveredCity("")}
              >
                <img src={pinDotImage} alt="Pin" className="pin-image" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 company-city-container">
            <div
              className={`company-city ${
                hoveredCity || isMobile ? "highlight" : ""
              } ${isTransitioning ? "transitioning" : ""}`}
            >
              {isMobile ? cities[currentCityIndex] : hoveredCity}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
